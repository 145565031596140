<template>
  <div>
    <a-row :gutter="24" type="flex">
      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Workout Plan List<a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
              <a-col :span="24" :md="12" class="top-right-header">
                <a-button @click="uploadNewContentModal = true"
                  >Add New</a-button
                >
              </a-col>
            </a-row>
          </template>
          <a-table
            :columns="columnData"
            :data-source="workoutPlanList"
            :pagination="false"
            :scroll="{ x: true }"
          >
            <template slot="name" slot-scope="text">
              <a>{{ text }}</a>
            </template>
            <template slot="profileImage" slot-scope="row">
              <img :src="row.image" :alt="row.name" width="100" height="100" />
            </template>
            <template slot="uploadBtn" slot-scope="row">
              <div>
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  @click="showDetailsModal(row.details)"
                >
                  <path
                    d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
                  />
                </svg>
              </div>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <!-- / Projects Table Column -->

      <!--      Update Profile Image-->
      <a-modal
        v-model:uploadNewContentModal="uploadNewContentModal"
        title="Workout Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUploadNewContentModal"
      >
        <a-row>
          <a-col :span="6">
            <div>Title</div>
          </a-col>
          <a-col :span="18">
            <a-input
              v-model:titleEn="workoutPlan.title.en"
              placeholder="Title English"
            />
            <div style="margin: 6px 0" />
            <a-input
              v-model:titleMy="workoutPlan.title.my"
              placeholder="Title Myanmar"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 6px">
          <a-col :span="6">
            <div>Publish Date</div>
          </a-col>
          <a-col :span="18">
            <a-date-picker
              v-model:selectedDate="selectedDate"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              style="width: 100%"
            />
          </a-col>
        </a-row>

        <a-row style="margin-top: 6px">
          <a-col :span="6">
            <div>Upload Video file</div>
          </a-col>
          <a-col :span="18">
            <a-input
              type="file"
              @change="onFileChange"
              placeholder="Basic usage"
              accept="video/mp4,video/x-m4v,video/*"
            />
          </a-col>
        </a-row>

        <a-row style="margin-top: 6px">
          <a-col :span="24">
            <div style="margin-bottom: 5px">Descripiton</div>
          </a-col>
          <a-col :span="24">
            <vue-editor
              v-model="workoutPlan.descripiton.en"
              placeholder="English descripiton"
            />
            <div style="margin: 6px 0" />
            <vue-editor
              v-model="workoutPlan.descripiton.my"
              placeholder="Myanmar descripiton"
            />
          </a-col>
        </a-row>
      </a-modal>

      <!-- Workout Content Details -->
      <a-modal
        width="500px"
        v-model:detailsModal="detailsModal"
        title="Feature Content Details"
        @ok="closeDetailsModal"
        @cancel="closeDetailsModal"
      >
        <div class="content-details">
          <div>
            ID: <span>{{ contentDetails.id }}</span>
          </div>
          <div>
            Publish Date: <span>{{ contentDetails.publish_date }}</span>
          </div>

          <div>
            Content File : <span>{{ contentDetails.video_link }}</span>
          </div>
          <video
            width="320"
            height="240"
            controls
            v-if="Object.keys(contentDetails).length"
          >
            <source :src="contentDetails.video_link" />
            Your browser does not support the audio element.
          </video>
        </div>
      </a-modal>
    </a-row>
  </div>
</template>
<script>
import moment from "moment/moment";
import { sendRequest } from "@/http/axios.method";
import { VueEditor } from "vue2-editor";
import { message } from "ant-design-vue";

const COLUMN_DATA = [
  {
    title: "Title (English)",
    dataIndex: "nameEn",
    class: "font-semibold text-muted",
  },
  {
    title: "Title (Mayanmar)",
    dataIndex: "nameMy",
    class: "font-semibold text-muted",
  },
  {
    title: "Publish Date",
    dataIndex: "publishDate",
    class: "font-semibold text-muted",
  },
  {
    title: "Status",
    dataIndex: "status",
    class: "font-semibold text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "uploadBtn" },
    width: 200,
  },
];

export default {
  name: "WorkoutPlan",
  components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      workoutPlans: [],
      workoutPlanData: [],
      confirmLoading: false,

      uploadNewContentModal: false,
      selectedDate: "",
      fileContent: "",
      workoutPlan: {
        title: {
          en: "",
          my: "",
        },
        descripiton: {
          en: "",
          my: "",
        },

        publish_date: "",
      },
      detailsModal: false,
      contentDetails: [],
    };
  },
  created() {
    this.getWorkoutPlanList();
  },
  methods: {
    disabledDate(event) {
      return moment().isSameOrAfter(moment(event));
    },
    getWorkoutPlanList() {
      this.confirmLoading = true;
      sendRequest("get", "admin/workout-plans", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          console.log(response);
          this.workoutPlans = response.data;
          this.workoutPlanData = this.workoutPlans.map(function (
            workout,
            label
          ) {
            return {
              key: label + 1,
              nameEn: workout.name["en"],
              nameMy: workout.name["my"],
              publishDate: workout.publish_date,
              status: workout.status ? "Active" : "Inactive",
              details: workout,
            };
          });
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onFileChange(e) {
      this.fileContent = e.target.files[0];
    },

    closeUploadNewContentModal() {
      this.confirmLoading = true;
      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser.token,
      };
      formData.append("name[en]", this.workoutPlan.title.en);
      formData.append("name[my]", this.workoutPlan.title.my);
      formData.append("description[en]", this.workoutPlan.descripiton.en);
      formData.append("description[my]", this.workoutPlan.descripiton.my);
      formData.append("plan_type", "weight_loss");
      formData.append("video_link", this.fileContent);

      formData.append(
        "publish_date",
        moment(this.selectedDate).format("YYYY-MM-DD")
      );

      sendRequest("post", "admin/workout-plans", formData, configHeader)
        .then((response) => {
          if (response.success) {
            this.confirmLoading = false;
            this.uploadNewContentModal = false;
            this.fileContent = "";
            this.getWorkoutPlanList();
          } else {
            if (response.status_code === 422) {
              message.error(response.message);
            }
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    showDetailsModal(content) {
      this.detailsModal = true;
      this.contentDetails = content;
    },
    closeDetailsModal() {
      this.contentDetails = [];
      this.detailsModal = false;
    },
  },
  computed: {
    workoutPlanList: function () {
      return this.workoutPlanData;
    },
  },
};
</script>
